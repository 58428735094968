<template>
   <div class="post-job">
        <el-form ref="jobFormRef" :model="jobForm" :rules="JobRules" label-width="100px">
            <el-card class="box-card" body-style="{ padding: '20px 20px 0 20px' }">
                <div slot="header" class="clearfix">
                    <span>职位信息</span>
                </div>
                <el-form-item label="职位名称: " prop="workName">
                    <el-input v-model="jobForm.workName" :disabled="jobForm.id>0" style="width:500px" placeholder="请填写需要招聘的职位" size="small"></el-input>
                </el-form-item>
                <!-- <el-popover
                    placement="bottom-start"
                    width="800"
                    trigger="click">
                    <div class="subCate">
                        <div class="subTitle">生活 | 服务业</div>
                        <ul class="subCateName">
                            <li>
                                <el-popover
                                    placement="bottom-start"
                                    width="420"
                                    trigger="hover"
                                    >
                                    <div>
                                        <ul class="subChildrenCate">
                                            <li @click="checkJob('服务员')">服务员</li>
                                            <li @click="checkJob('送餐员')">送餐员</li>
                                            <li @click="checkJob('厨师/厨师长')">厨师/厨师长</li>
                                            <li @click="checkJob('后厨')">后厨</li>
                                            <li @click="checkJob('传菜员')">传菜员</li>
                                        </ul>
                                    </div>
                                    <span class="sub-link" slot="reference">餐饮<i class="el-icon-arrow-down"></i></span>
                                </el-popover>
                            </li>
                            <li>
                                <el-popover
                                    placement="bottom-start"
                                    width="420"
                                    trigger="hover"
                                    >
                                    <div>
                                        <ul class="subChildrenCate">
                                            <li @click="checkJob('服务员')">服务员</li>
                                            <li @click="checkJob('送餐员')">送餐员</li>
                                            <li @click="checkJob('厨师/厨师长')">厨师/厨师长</li>
                                            <li @click="checkJob('后厨')">后厨</li>
                                            <li @click="checkJob('传菜员')">传菜员</li>
                                        </ul>
                                    </div>
                                    <span class="sub-link" slot="reference">家政保洁/安保<i class="el-icon-arrow-down"></i></span>
                                </el-popover>
                            </li>
                            <li>
                                <el-popover
                                    placement="bottom-start"
                                    width="420"
                                    trigger="hover"
                                    >
                                    <div>
                                        <ul class="subChildrenCate">
                                            <li @click="checkJob('服务员')">服务员</li>
                                            <li @click="checkJob('送餐员')">送餐员</li>
                                            <li @click="checkJob('厨师/厨师长')">厨师/厨师长</li>
                                            <li @click="checkJob('后厨')">后厨</li>
                                            <li @click="checkJob('传菜员')">传菜员</li>
                                        </ul>
                                    </div>
                                    <span class="sub-link" slot="reference">美容美发<i class="el-icon-arrow-down"></i></span>
                                </el-popover>
                            </li>
                            <li>
                                <el-popover
                                    placement="bottom-start"
                                    width="420"
                                    trigger="hover"
                                    >
                                    <div>
                                        <ul class="subChildrenCate">
                                            <li @click="checkJob('服务员')">服务员</li>
                                            <li @click="checkJob('送餐员')">送餐员</li>
                                            <li @click="checkJob('厨师/厨师长')">厨师/厨师长</li>
                                            <li @click="checkJob('后厨')">后厨</li>
                                            <li @click="checkJob('传菜员')">传菜员</li>
                                        </ul>
                                    </div>
                                    <span class="sub-link" slot="reference">酒店<i class="el-icon-arrow-down"></i></span>
                                </el-popover>
                            </li>
                        </ul>
                    </div>
                    <el-form-item label="职位类型: " slot="reference">
                        <el-input v-model="jobForm.name" style="width:500px" placeholder="请填写需要招聘的职位"></el-input>
                    </el-form-item>
                </el-popover> -->
                <el-form-item label="职位类型: " prop="industryId">
                    <!-- <el-select v-model="jobForm.industryId" :disabled="jobForm.id>0" placeholder="请选择公司所属行业" style="width:500px">
                        <el-option
                        v-for="item in industryList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select> -->
                    <el-cascader
                        v-model="jobForm.industryId"
                        :options="jobList"
                        :props="jobProps"
                        size="small"
                        style="width:500px"
                        @change="handleJobChange">
                    </el-cascader>
                </el-form-item>
                <el-form-item label="职位性质: ">
                    <el-select v-model="jobForm.workType" size="small" placeholder="请选择">
                        <el-option label="全职" :value="1"></el-option>
                        <el-option label="灵活用工岗位" :value="2"></el-option>
                        <!-- <el-option label="全职/灵活用工岗位" :value="3"></el-option> -->
                        <el-option label="实习" :value="4"></el-option>
                        <!-- <el-option label="共享用工" :value="5"></el-option> -->
                    </el-select>
                </el-form-item>
                <el-form-item label="招聘人数: " prop="personNumber">
                    <el-input placeholder="请输入招聘人数，只能填写整数" v-model="jobForm.personNumber" style="width:500px" type="number" @change="checkNumber('number')" size="small">
                        <template slot="append">人</template>
                    </el-input>
                </el-form-item>
                <!-- <el-form-item label="每月薪资: " prop="minPrice">
                    <el-input v-model="jobForm.minPrice" style="width:240px" placeholder="请填写岗位最低工资" @change="checkNumber('min')" type="number" size="small"></el-input> 至
                    <el-input v-model="jobForm.maxPrice" style="width:240px" placeholder="请填写岗位最高工资" @change="checkNumber('max')" type="number" size="small"></el-input>
                </el-form-item> -->
                <el-form-item label="每月薪资: " required>
                   <el-select v-model="jobForm.wagesId" placeholder="请选择工资标准" style="width:500px" size="small">
                        <el-option
                        v-for="item in wagesList"
                        :key="item.id"
                        size="small"
                        :label="item.desc"
                        :value="item.id">
                    </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="职位福利: " prop="workWelfare">
                    <div class="welfare">
                        <el-button size="small" v-for="item in positionAdvantageList" :key="item.id" :class="[jobForm.workWelfare.includes(item.name)?'active':'']" @click="choosePositionAdvantage(item.name)">{{item.name}}</el-button>
                        <el-input v-model="otherPositionAdvantage" style="width:150px" placeholder="请填写其他福利" size="small"></el-input>
                        <span class="add-welfare" @click="addPositionAdvantage()">添加</span>
                    </div>
                </el-form-item>
                <el-form-item label="学历要求: " prop="workEducation">
                    <div class="welfare">
                        <el-button size="small" v-for="item in educationList" :key="`educationList${item.id}`" :class="jobForm.workEducation===item.name?'active': ''" @click="chooseEducation(item.name)">{{item.name}}</el-button>
                    </div>
                </el-form-item>
                <el-form-item label="工作年限: ">
                    <div class="welfare">
                        <el-button size="small" v-for="item in workYearList" :key="`workYearList${item.id}`" :class="jobForm.workYears===item.name?'active': ''" @click="chooseWorkYear(item.name)">{{item.name}}</el-button>
                    </div>
                </el-form-item>
                <el-form-item label="工作区域: " required>
                    <!-- <el-input v-model="jobForm.address" style="width:500px" placeholder="请填写该职位所在工作地点" size="small"></el-input> -->
                    <div class="work-layer flex">
                        <el-select v-model="province" placeholder="请选择省份" @change="handleProvinceChange" size="small" style="width:150px;margin-right:10px">
                            <el-option
                            v-for="item in provinceList"
                            :key="item.id"
                            :label="item.districtName"
                            :value="item.id"
                            >
                            </el-option>
                        </el-select>
                        <el-select v-model="city" placeholder="请选择城市" @change="handlecityChange" size="small" style="width:150px;margin-right:10px">
                            <el-option
                            v-for="item in cityList"
                            :key="item.id"
                            :label="item.districtName"
                            :value="item.id">
                            </el-option>
                        </el-select>
                        <el-select v-model="area" v-if="areaList.length>0" placeholder="请选择区县" @change="handleAreaChange" size="small" style="width:150px;margin-right:10px">
                            <el-option
                            v-for="item in areaList"
                            :key="item.id"
                            :label="item.districtName"
                            :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </el-form-item>
                <el-form-item label="详细地址: " prop="address">
                    <el-input v-model="jobForm.address" style="width:500px" placeholder="请填写该职位所在工作地点（如街道、小区、楼栋号等）" size="small"></el-input>
                </el-form-item>
            </el-card>
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <span>职位描述</span>
                </div>
                <el-form-item label="职位描述: " prop="workDetail">
                    <el-input
                        type="textarea"
                        :rows="8"
                        style="width:500px"
                        size="small"
                        placeholder="请输入职位详细信息"
                        v-model="jobForm.workDetail">
                        </el-input>
                </el-form-item>
            </el-card>
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <span>联系方式</span>
                </div>
                <el-form-item label="联系人: " prop="contacts">
                    <el-input v-model="jobForm.contacts" style="width:500px" placeholder="请填写联系人姓名" size="small"></el-input>
                </el-form-item>
                <el-form-item label="联系电话: " prop="contactsPhone">
                    <el-input v-model="jobForm.contactsPhone" style="width:500px" placeholder="请填写电话或手机号码" size="small"></el-input>
                </el-form-item>
                <el-form-item label="邮箱地址: ">
                    <el-input placeholder="请输填写邮箱地址" v-model="jobForm.contactsEmail" size="small" style="width:500px">
                    </el-input>
                </el-form-item>
            </el-card>
            <el-button size="medium" class="active submit" @click="releaseJob" :loading="loading">{{jobForm.id>0?'修改':'发布'}}职位</el-button>
        </el-form>
    </div>
</template>
<script>
import companyRequest from '../../../api/company'
import { Message } from 'element-ui'
// 验证手机号的规则
let checkMobile = (rule, value, cb) => {
    const regMobile = /^1[0-9]{10}$/

    if (regMobile.test(value)) {
    return cb()
    }
    cb(new Error('请输入合法的手机号'))
}
export default {
    created() {
        // console.log(this.$route.query)
        this.jobForm.id = parseInt(this.$route.query.id) || null

        this.userInfo=JSON.parse(localStorage.getItem("userInfo"))

        this.jobForm.contacts=this.userInfo.legalName
        this.jobForm.contactsPhone=this.userInfo.phone

    },
    mounted() {
        if (window.localStorage.getItem('authInfoStatus') != '1') {
            // console.log('authInfoStatus11', window.localStorage.getItem('authInfoStatus'))
            this.$alert('当前账号正在审核中,暂不能发布职位~', '审核中', {
                confirmButtonText: '确定',
                callback: action => {
                    this.$router.back()
                }
            })
        }
        this.getIndustry()
        this.queryIndustry()
        this.getCityList('provinceList', 1)
        // this.getCityList('cityList', 17)
        // this.getCityList('areaList', 240)
        //存在职位id时， 获取职位信息
        if (this.jobForm.id > 0) {
            this.getJobInfo()
            // console.log('jobForm.id', this.jobForm.id)
        } else {
            this.positionAdvantageList = [ //职位福利
                {id: 1, name: '五险一金'},
                {id: 2, name: '包住'},
                {id: 3, name: '包吃'},
                {id: 4, name: '年底双薪'},
                {id: 5, name: '交通补助'},
                {id: 6, name: '饭补'},
                {id: 7, name: '加班补助'},
            ]
        }

    },
    beforeRouteUpdate(to,from,next){
        console.log('路由更新')
        console.log('路由信息', this.$route)
        if (!this.$route.query.id) {
             console.log(333)
            // this.$router.replace({
            //     path: '/company/postJob'
            // })
            // this.jobForm.id = null
            // this.jobForm.workName = ''
            // this.jobForm.industryId = null
            // this.jobForm.personNumber = null
            // this.jobForm.minPrice = null
            // this.jobForm.maxPrice = null
            // this.jobForm.workWelfare = []
            // this.jobForm.workEducation = '不限'
            // this.jobForm.workYears = '不限'
            // this.jobForm.address = ''
            // this.jobForm.workDetail =  `工作内容: \n职位要求: \n工作时间: `
            // this.jobForm.contacts =  ''
            // this.jobForm.contactsPhone =  null
            // this.jobForm.contactsEmail =  ''

            // this.positionAdvantageList = [ //职位福利
            //     {id: 1, name: '五险一金'},
            //     {id: 2, name: '包住'},
            //     {id: 3, name: '包吃'},
            //     {id: 4, name: '年底双薪'},
            //     {id: 5, name: '交通补助'},
            //     {id: 6, name: '饭补'},
            //     {id: 7, name: '加班补助'},
            // ]
        } else {
            console.log(444)
        }
        next()
    },
    data() {
        return {
            userInfo:null,
            loading: false,
            industryList: [], //行业分类
            jobList: [], //职位分类
            otherPositionAdvantage: '', //添加福利
            provinceList: [], //省列表
            cityList: [], //市列表
            areaList: [], //区域列表
            province: '', //选中省
            city: '', //选中市
            area: '', //区域
            //发布职位表单信息
            jobForm: {
                workArea: '', //区域id
                id: null, //职位id  添加为null 修改为id
                workName: '', //职位名称
                industryId: null, //职位类型
                personNumber: null, //招聘人数
                //minPrice: null, //最低工资
                //maxPrice: null, //最高工资
                wagesId: null, //薪资标准
                workWelfare: [], //职位福利
                workEducation: '学历不限', //学历
                workYears: '经验不限', //工作年限
                address: '', //地址
                workType: 1, //职位性质
                workDetail: `工作内容: \n职位要求: \n工作时间: `, //职位详细描述
                contacts: '', //联系人
                contactsPhone: null, //联系人电话
                contactsEmail: '' //邮箱
            },
            wagesList: [ //薪资标准
                {id: 0, desc: '面议'},
                {id: 1, desc: '1000-2000元'},
                {id: 2, desc: '2000-3000元'},
                {id: 3, desc: '3000-5000元'},
                {id: 4, desc: '5000-8000元'},
                {id: 5, desc: '8000-12000元'},
                {id: 6, desc: '12000-15000元'},
                {id: 7, desc: '15000-20000元'},
                {id: 8, desc: '20000元以上'},
            ],
            jobProps: {
                expandTrigger: 'hover',
                label: 'name',
                value: 'id',
                children: 'children'
            },
            //表单验证规则
            JobRules: {
                workName: [{ required: true, message: '请输入职位名称', trigger: 'blur' },],
                industryId: [{ required: true, message: '请选择职位类型', trigger: 'change' },],
                personNumber: [{ required: true, message: '请输入招聘人数', trigger: 'biur' },],
                //minPrice: [{ required: true, message: '请输入最低工资', trigger: 'blur' },],
                //maxPrice: [{ required: true, message: '请输入最高工资', trigger: 'blur' },],
                workWelfare: [{ required: true, message: '请选择职位福利', trigger: 'blur' },],
                workEducation: [{ required: true, message: '请选择学历', trigger: 'blur' },],
                workYears: [{ required: true, message: '请选择工作年限', trigger: 'blur' },],
                address: [{ required: true, message: '请填写工作地址', trigger: 'blur' },],
                workDetail: [{ required: true, message: '请填写职位工作职责', trigger: 'blur' },],
                contacts: [{ required: true, message: '请填写联系人', trigger: 'blur' },],
                contactsPhone: [{ required: true, message: '请填写联系电话', trigger: 'blur' }, { validator: checkMobile, trigger: 'blur' }],
            },
            // positionAdvantageList: [],
            positionAdvantageList: [ //职位福利
                {id: 1, name: '五险一金'},
                {id: 2, name: '包住'},
                {id: 3, name: '包吃'},
                {id: 4, name: '年底双薪'},
                {id: 5, name: '交通补助'},
                {id: 6, name: '饭补'},
                {id: 7, name: '加班补助'},
            ],
            educationList: [ //学历
                {id: 1, name: '学历不限'},
                {id: 2, name: '初中及以下'},
                {id: 3, name: '高中'},
                {id: 4, name: '中专/中技'},
                {id: 5, name: '大专'},
                {id: 6, name: '本科'},
                {id: 7, name: '硕士'},
                {id: 8, name: 'MBA/EMBA'},
                {id: 9, name: '博士'},
            ],
            workYearList: [ //工作年限
                {id: 1, name: '经验不限'},
                {id: 2, name: '1年以下'},
                {id: 3, name: '1-2年'},
                {id: 4, name: '3-5年'},
                {id: 5, name: '6-7年'},
                {id: 6, name: '8-10年'},
                {id: 7, name: '10年以上'},
            ]
        }
    },
    methods: {
        //获取公司行业分类
        getIndustry() {
            companyRequest.getIndustry().then((res) => {
                if (res.code === 200) {
                    this.industryList = res.data
                }
            })
        },
        //省份发生变化
        handleProvinceChange(val) {
            // console.log('val', val)
            this.getCityList('cityList', val)
            this.city = ''
            this.areaList = []
            this.area = ''
        },
        //城市发生变化
        handlecityChange(val) {
            // console.log('val', val)
            this.getCityList('areaList', val)
            this.area = ''
        },
        //区域发生变化
        handleAreaChange(val) {
            // console.log('val', val)
        },
        //获取职位分类
        queryIndustry() {
            companyRequest.queryAllIndustry().then((res) => {
                if (res.code === 200) {
                    this.jobList = res.data
                    // console.log('jobList', this.jobList)
                }
            })
        },
        //城市联动
        getCityList(location, id) {
            companyRequest.getCityList({id: id}).then((res) => {
                if (res.code === 200) {
                    this[location] = res.data
                    // console.log('location', location)
                }
            })
        },
        //职位级联选择发生变化
        handleJobChange(val) {
            this.jobForm.industryId = val
            // console.log('industryId', this.jobForm.industryId)
        },
        //获取职位信息
        getJobInfo() {
            companyRequest.getJobInfo({workId: this.jobForm.id}).then((res) => {
                if (res.code === 200) {
                    //回填数据
                    this.jobForm = res.data
                    this.jobForm.industryId = res.data.industryId.split(',')
                    //职位字符串转数字
                    this.jobForm.industryId = this.jobForm.industryId.map((item) => {
                        return item = parseInt(item)
                    })
                    //城市回填
                    this.jobForm.workArea = res.data.workArea.split(',')
                    this.jobForm.workArea = this.jobForm.workArea.map((item) => {
                        return item = parseInt(item)
                    })
                    // console.log('workArea', this.jobForm.workArea)
                    this.province = this.jobForm.workArea[0]
                    this.city = this.jobForm.workArea[1]
                    this.area = this.jobForm.workArea[2]
                    //拉取城市相关数据
                    this.getCityList('cityList', this.province)
                    this.getCityList('areaList', this.city)
                    //分割成数组
                    this.jobForm.workWelfare = res.data.workWelfare.split(',')
                    let list = []
                    let oterList = []
                    let applyList = []
                    this.positionAdvantageList.forEach((item) => {
                        list.push(item.name)
                    })
                    oterList = list.concat(this.jobForm.workWelfare)
                    // console.log('oterList', oterList)
                    //去重
                    applyList = Array.from(new Set(oterList))
                    // console.log('applyList', applyList)
                    //清空职位福利列表
                    this.positionAdvantageList = []
                    applyList.forEach((item, index) => {
                        this.positionAdvantageList.push({
                            id: index,
                            name: item
                        })
                    })
                    // console.log('jobForm', this.jobForm)
                    // list.forEach(item => {
                    //     this.jobForm.workWelfare.forEach(row => {
                    //         console.log(row === item.name)
                    //     })
                    // })
                    // this.positionAdvantageList.forEach((item, index) => {
                    //     console.log('item.name', item.name)
                    //     this.jobForm.workWelfare.forEach((item1, index1) => {
                    //         console.log('item1', item1)
                    //         console.log(item.name+'' == item1+'')
                    //         if (item.name+'' == item1+'') {
                    //             console.log('不相等')
                    //             this.positionAdvantageList.push({
                    //                 id: this.positionAdvantageList.length + 1,
                    //                 name: item1
                    //             })
                    //         }
                    //     })
                    // })
                    // console.log('positionAdvantageList', this.positionAdvantageList)
                    // this.jobForm.workWelfare.forEach((item, index) => {
                    //     this.positionAdvantageList.push({
                    //         id: index,
                    //         name: item
                    //     })
                    // })
                }
            })
        },
        //添加福利
        addPositionAdvantage() {
            // console.log(this.otherPositionAdvantage)
            if (!this.otherPositionAdvantage.trim()) {
                this.$message.error('新增福利不能为空~')
                return
            }
            this.positionAdvantageList.push({
                id: this.positionAdvantageList.length + 1,
                name: this.otherPositionAdvantage
            })
            this.otherPositionAdvantage = ''
        },
        //选择福利
        choosePositionAdvantage(row) {
            if (this.jobForm.workWelfare.includes(row)) {
                this.jobForm.workWelfare = this.jobForm.workWelfare.filter((item1) => {
                    return item1 !== row
                })
            } else {
                this.jobForm.workWelfare.push(row)
            }
            // console.log('职位福利', this.jobForm.workWelfare)
        },
        //选择学历
        chooseEducation(row) {
            this.jobForm.workEducation = row
            // console.log(this.jobForm.workEducation)
        },
        //选择工作年限
        chooseWorkYear(row) {
             this.jobForm.workYears = row
        },
        //选择职位类型
        checkJob(jobName) {
            this.jobForm.name = jobName
        },
        releaseJob() {
            if (window.localStorage.getItem('authInfoStatus') != '1') {
                // console.log('authInfoStatus11', window.localStorage.getItem('authInfoStatus'))
                this.$alert('当前账号正在审核中,暂不能发布职位~', '审核中', {
                    confirmButtonText: '确定',
                    callback: action => {}
                })
                return
            }
            // console.log('authInfoStatus', window.localStorage.getItem('authInfoStatus'))
            this.$refs.jobFormRef.validate((valid) => {
                if (valid) {
                    if (!this.province) {
                        this.$message.error('省份不能为空')
                        return
                    }
                    if (!this.city) {
                        this.$message.error('城市不能为空')
                        return
                    }
                    if (this.areaList.length > 0) {
                        if (!this.area) {
                            this.$message.error('区域不能为空')
                            return
                        }
                    }
                    this.loading = true
                    const obj = JSON.parse(JSON.stringify(this.jobForm))
                    obj.industryId = obj.industryId.join(',')
                    obj.workArea = `${this.province},${this.city},${this.area}`
                    companyRequest.releaseJob(obj).then((res) => {
                        if (res.code === 200) {
                            this.$message.success('发布成功')
                            this.loading = false
                            setTimeout(() => {
                                this.$router.push({
                                    path: '/company/jobManagement'
                                })
                            }, 1500)
                        }
                    }).catch((err) => {
                        this.$message.error(err)
                        this.loading = false
                    })
                    // console.log('jobForm', this.jobForm)
                } else {
                    // this.$message.error('还有部分招聘信息未完善')
                    this.$alert('带 * 号内容为必填项，请先补全信息再提交~', '信息缺失', {
                        confirmButtonText: '确定',
                        callback: action => {}
                    })
                    return false;
                }
            })
        },
        //验证数字是否大于0
        checkNumber(type) {
            // console.log('type', type)
            if (type === 'number') {
                if (this.jobForm.personNumber < 1) {
                    this.$message({
                        message: '招聘人数最少为1人',
                        type: 'warning'
                    })
                    this.jobForm.personNumber = 1
                } else {
                    this.jobForm.personNumber = parseInt(this.jobForm.personNumber)
                }
            } else if (type === 'min') {
                if (this.jobForm.minPrice < 0) {
                    // console.log(11)
                    this.$message({
                        message: '最低工资不能低于0元',
                        type: 'warning'
                    })
                    this.jobForm.minPrice = 0
                }
            } else {
                if (this.jobForm.maxPrice < 0) {
                    this.$message({
                        message: '最高工资不能低于0元',
                        type: 'warning'
                    })
                    this.jobForm.maxPrice = 0
                }
            }

        }
    },
    watch: {
        // province(val) {
        //     console.log(val)
        //     console.log('province', this.province)
        //     this.getCityList('cityList', val)
        //     this.city = ''
        //     this.areaList = []
        //     this.area = ''
        // },
        // city(val) {
        //     console.log(val)
        //     console.log('city', this.city)
        //      this.getCityList('areaList', val)
        // },
        // area(val) {
        //     console.log(val)
        //     console.log('area', this.area)
        // }
    }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/css/global.scss';
    .active{
        background: $main;
        color: #fff;
    }
    .submit{
        width: 15%;
        &:hover{
            background: $hover;
        }
    }
    >>>.el-card__body{
        padding: 20px 20px 0 20px !important;
    }
    .el-popover{
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1) !important;
    }
    .el-button{
        margin-left: 0;
        margin-right: 15px;
    }
    .subCate{
        display: flex;
        width: 800px;
        overflow: hidden;
        cursor: pointer;
        padding: 6px 0;
        box-sizing: border-box;
        .subTitle{
            width: 150px;
            font-size: 12px;
            text-align: right;
            padding-right: 30px;
            color: #999;
        }
        .subCateName{
            display: flex;
            flex-wrap: wrap;
            width: 650px;
            li{
                width: 130px;
                font-size: 12px;
                // margin-top: -3px;
                padding-bottom: 10px;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                &:hover{
                        color: $main;
                    }
                .sub-link{
                    margin-right: 5px;

                }
            }
        }
    }
    .add-welfare{
        margin-left: 5px;
        cursor: pointer;
        font-size: 12px;
        color: $main;
        font-weight: 550;
    }
    .subChildrenCate{
        display: flex !important;
        box-sizing: border-box;
        flex-wrap: wrap;
        width: 420px;
        overflow: hidden;
        li{
            width: 33.3333%;
            font-size: 12px;
            margin-bottom: 8px;
            cursor: pointer;
            &:hover{
                color: $main;
            }
        }
    }
    .welfare{
        // width: 500px;
        overflow: hidden;
    }
</style>
